<template>
  <div class="wrap">
    <div class="The_header">
      <ul class="bg-fff">
        <li class="left">
          <router-link to="/profile/user_info">
            <i class="iconfont icon-fanhui"></i>
          </router-link>
        </li>
        <li>{{ t("common.title.transPassword") }}</li>
      </ul>
    </div>
    <div v-if="userDetail" class="main main_page">
      <!-- 修改提现密码 -->
      <div class="Sys_form Sys_form_b">
        <AForm
          layout="vertical"
          :model="formModel"
          ref="formRef"
          :rules="resetRules"
          validateTrigger="blur"
          @submit.prevent="handleUpdate"
          autocomplete="off"
        >
          <h3>{{ t("profile.label.changePassword") }}</h3>
          <div class="box">
            <ul>
              <li>{{ t("profile.label.myCellNo") }}</li>
              <li class="li-code">
                <span class="span_text">
                  {{ formatMobile(userDetail.userAccount) }}
                </span>
                <!-- <span class="span_button">
                  <button v-if="cooldown <= 0" @click="sendCode" type="button">
                    {{ t("common.button.getVerificationCode") }}
                  </button>
                  <button v-else disabled type="button">
                    {{ t("common.button.coolDown", { second: cooldown }) }}
                  </button>
                </span> -->
              </li>
            </ul>
            <!-- <ul>
              <li>{{ t("common.title.verificationCode") }}</li>
              <li>
                <AFormItem name="Code">
                  <AInput
                    type="text"
                    v-model:value="formModel.identifyCode"
                    :placeholder="t('account.placeholder.verificationCode')"
                  />
                </AFormItem>
              </li>
            </ul> -->
            <ul>
              <li>{{ t("profile.label.newPassword") }}</li>
              <li>
                <AFormItem name="newpwd">
                  <AInput
                    type="password"
                    v-model:value="formModel.newpwd"
                    :placeholder="t('profile.placeholder.newPassword')"
                  />
                </AFormItem>
              </li>
            </ul>
            <ul>
              <li>{{ t("profile.label.confirmPasswordLower") }}</li>
              <li>
                <AFormItem name="confirmpwd">
                  <AInput
                    type="password"
                    v-model:value="formModel.confirmpwd"
                    :placeholder="t('profile.placeholder.confirmPassword')"
                  />
                </AFormItem>
              </li>
            </ul>
          </div>
          <div class="Sys_tips c-999">
            {{ t("profile.hits.transPassword") }}
          </div>
          <div class="Sys_submit_btn withdraw_password Sys_submit_btn_c">
            <button type="submit">
              {{ t("common.button.saveInformation") }}
            </button>
          </div>
        </AForm>
      </div>
    </div>
  </div>
  <Loading :loading="loading" />
</template>

<script>
import { ref, reactive, computed, unref, getCurrentInstance } from "vue";

import { formatMobile } from "@/utils/formatter";
import { useRouter } from "vue-router";
import Loading from "@/components/Loading.vue";
import { useI18n } from "@/lang";
import { userDetail } from "@/hooks/userDetail";
import { sysDataDictionaryWbObj } from "@/hooks/sysDataDictionaryWbObj";
import {
  MOBILE_COUNTRY_CODE,
  // RE_PHONE_NUMBER
} from "@/utils/constant";
import api from "@/api";
export default {
  components: { Loading },
  setup() {
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const router = useRouter();
    const formModel = reactive({
      // identifyCode: null,
      newpwd: "",
      confirmpwd: "",
    });
    let loading = ref(false);
    let visible = ref(false);
    let cooldown = ref(0);

    const back = computed(() => {
      const {
        query: { back },
      } = unref(router.currentRoute);
      return back;
    });

    function handleConfirm() {
      visible.value = false;
    }

    let validatePass = async (rule, value) => {
      if (value === "") {
        return;
      } else if (value !== formModel.newpwd) {
        return Promise.reject(t("profile.placeholder.passwordMath"));
      } else if (value.length > 30 || value.length < 4) {
        return Promise.reject(
          t("extra.minLength", { min: 4 }) + t("extra.maxLength", { max: 30 })
        );
      } else {
        return Promise.resolve();
      }
    };

    // async function sendIdentifyCode() {
    //   let res = await api.sendSms(userDetail.value.userAccount);
    //   let sendIdentifyCode = res && res.data;
    //   if (!sendIdentifyCode) {
    //     return;
    //   }
    //   cooldown.value = sendIdentifyCode.data;

    //   return sendIdentifyCode.success
    //     ? Promise.resolve()
    //     : Promise.reject(t("account.signup.smsFailed"));
    // }

    // async function sendCode() {
    //   await sendIdentifyCode();

    //   let cooldownTimer = setInterval(() => {
    //     if (cooldown.value > 0) {
    //       cooldown.value--;
    //     } else {
    //       clearInterval(cooldownTimer);
    //     }
    //   }, 1000);
    // }

    const resetRules = reactive({
      // identifyCode: [
      //   {
      //     required: true,
      //     message: t("error.validator.identifyCode.required"),
      //   },
      // ],
      newpwd: [
        {
          validator: validatePass,
        },
        {
          required: true,
          message: t("profile.placeholder.newPassword"),
        },
      ],
      confirmpwd: [
        {
          validator: validatePass,
        },
        {
          required: true,
          message: t("profile.placeholder.confirmPassword"),
        },
      ],
    });

    async function handleUpdate() {
      let validateResult = await proxy.$refs["formRef"]
        .validate()
        .then((e) => {
          return e;
        })
        .catch((e) => {
          return e;
        });
      if (
        !(
          validateResult &&
          validateResult.errorFields &&
          validateResult.errorFields.length === 0
        )
      ) {
        return;
      }
      await handleSubmit();
    }

    async function handleSubmit() {
      loading.value = true;
      try {
        let formToSend = {
          userDebitPassword: formModel.newpwd,
          // smsCode: formModel.identifyCode,
        };
        let res = await api.userDebitPassword(formToSend);
        if (res && res.data && res.data.success) {
          await api.getUserDetails();
          if (!back?.value) {
            await router.replace("/profile/user_info");
          } else {
            await router.replace("/withdraw");
          }
          setTimeout(() => {
            api.getUserDetails();
          }, 500);
        }
      } finally {
        loading.value = false;
      }
    }

    return {
      t,
      visible,
      handleConfirm,
      formModel,
      resetRules,
      handleUpdate,
      loading,
      formatMobile,
      MOBILE_COUNTRY_CODE,
      cooldown,
      // sendCode,
      userDetail,
      sysDataDictionaryWbObj,
    };
  },
};
</script>

<style lang="scss">
.ant-input {
  border-bottom: none !important;
}

.ant-input:focus {
  border-bottom: none !important;
}

.ant-form-item-children input:focus {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0) !important;
}

.ant-form-vertical .ant-form-explain {
  // margin-top: -15px !important;
}

.Sys_submit_btn.withdraw_password {
  button {
    &[disabled] {
      background: #a0a0a0;
    }
  }
}
</style>
