<template>
  <div v-if="loading" class="loading">
    <a-spin>
      <div class="spin-content"></div>
    </a-spin>
  </div>
</template>

<script lang="js">
import { defineComponent, watch, ref } from 'vue';

export default defineComponent({
  name: 'Percentage',
  props: {
    loading: Boolean,
  },
  setup(props) {
    let loading = ref(false);
    watch(
      () => props.loading,
      () => {
        loading.value = props.loading;
      },
    );
  },
});
</script>
<style lang="scss" scoped>
.loading{
  position: fixed;
  z-index: 99999;
}
</style>